import { Component } from '@angular/core';
import { MenuItem } from 'src/app/core/interfaces/menu-item.interface';

const MENU_ITEMS: MenuItem[] = [
  {
    name: 'menu.procedures',
    icon: '',
    route: '/procedure/procedures',
    type: 'group',
    isOpen: false,
    children: [
      {
        name: 'menu.procedures_tabs.procedures',
        icon: 'toc',
        route: '/procedure/procedures',
        type: 'button',
      },
      {
        name: 'menu.procedures_tabs.departments',
        icon: 'segment',
        route: '/procedure/departments',
        type: 'button',
      },
      {
        name: 'menu.procedures_tabs.labs',
        icon: 'science',
        route: '/procedure/labs',
        type: 'button',
      },
      {
        name: 'menu.procedures_tabs.price_list',
        icon: 'attach_money',
        route: '/procedure/price-list',
        type: 'group',
        isOpen: false,
        children: [
          {
            name: 'menu.procedures_tabs.price_list_programs',
            icon: '',
            route: '/procedure/price-list/programs',
            type: 'button',
          },
        ],
      },
    ],
  },
];

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css'],
})
export class ProcedureComponent {
  menuItems: MenuItem[] = MENU_ITEMS;
}
