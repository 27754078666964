import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  environment,
  generateTokenHeader,
} from '../../../../environments/environment';
import { LoginRequestDTO } from '../dto/login-request.dto';
import { TokenManagerService } from '../../../core/services/token-manager/token-manager.service';
import { Authority } from '../../users/interfaces/authority.interface';
import { UserDTO } from '../dto/user.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private tokenManager: TokenManagerService
  ) {}

  login(loginRequestDTO: LoginRequestDTO) {
    return this.http.post<any>(environment.apiUrls.auth.login, loginRequestDTO);
  }

  validateToken(jwtToken: string, authority: string): Observable<boolean> {
    return this.http
      .get<any>(
        environment.apiUrls.auth.validate + '/' + authority,
        generateTokenHeader(jwtToken)
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return (response as unknown as boolean) === true;
        }),
        catchError((_) => {
          return [false];
        })
      );
  }

  getUserAuthorities(): Observable<String[]> {
    return this.http.get<any>(
      environment.apiUrls.auth.authorities,
      generateTokenHeader(this.tokenManager.getToken()!)
    );
  }

  isAuthorizedTo(neededAuthorizations: string[]): Observable<boolean> {
    return this.getUserAuthorities().pipe(
      map((authorities: String[]) => {
        return neededAuthorizations.some((neededAuthorization) =>
          authorities.includes(neededAuthorization)
        );
      })
    );
  }

  getConnectedUser(): Observable<UserDTO> {
    return this.http.get<UserDTO>(
      environment.apiUrls.users.me,
      generateTokenHeader(this.tokenManager.getToken()!)
    );
  }
}
