import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileDetails } from '../../interfaces/file-details';
import { FileType } from '../../interfaces/enums/file-types';

@Component({
  selector: 'app-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.css']
})
export class FilePreviewDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FilePreviewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  fileDetails: FileDetails | null = null;
  fileType = FileType;
  authenticatedFileUrl: string = '';

  ngOnInit() {
    this.fileDetails = this.data.fileDetail as FileDetails;
    this.authenticatedFileUrl = this.data.authenticatedFileUrl || '';
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
