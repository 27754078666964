import { Component, Input, OnInit } from '@angular/core';
import { faFile, faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FileDetails } from '../../interfaces/file-details';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FileDetailsDialogComponent } from '../file-details-dialog/file-details-dialog.component';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { FileType } from '../../interfaces/enums/file-types';
import { FileService } from '../../services/storage/file.service';


@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {
  @Input() file: FileDetails | null = null;
  fileType = FileType;

  faFile = faFile;
  fileMap = new Map([
    [FileType.PDF, faFilePdf],
    [FileType.WORD, faFileWord],
    [FileType.EXCEL, faFileExcel],
    [FileType.IMAGE, faFile],
    [FileType.GIF, faFile],
    [FileType.VIDEO, faFile],
    [FileType.OTHER, faFile]
  ]);

  authenticatedFileUrl: string | null = null;

  constructor(private dialog: MatDialog, private fileService: FileService) {} 

  ngOnInit() {
    if (this.file === null) {
      throw new Error("File is required");
    }
    this.loadAuthenticatedFileUrl();
  }

  onFileClick(event: Event) {
    event.preventDefault();
    if (this.file?.type === FileType.IMAGE || this.file?.type === FileType.GIF || this.file?.type === FileType.VIDEO || this.file?.type === FileType.PDF) {
      this.showFilePreview();
    }
    else{
      this.downloadFile();
    }
  }

  onFileInfoClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showFileDetails();
  }

  onFileDownloadClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.downloadFile();
  }

  private showFilePreview() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      fileDetail: this.file,
      authenticatedFileUrl: this.authenticatedFileUrl
    };

    this.dialog.open(FilePreviewDialogComponent, dialogConfig);
  }

  private showFileDetails() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      fileDetail: this.file,
    };

    this.dialog.open(FileDetailsDialogComponent, dialogConfig);
  }

  private downloadFile() {
    if (!this.file) return;

    this.fileService.downloadFile(this.file.fileUrl).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.file!.title || 'download';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  private loadAuthenticatedFileUrl() {
    if (!this.file) return;

    this.fileService.downloadFile(this.file.fileUrl).subscribe(blob => {
      this.authenticatedFileUrl = window.URL.createObjectURL(blob);
    });
  }
}
