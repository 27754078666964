<app-header
  [showMenuButton]="false"
  (menuButtonClicked)="column.toggleSidenav()"
></app-header>
<app-one-column #column [showMenu]="false">
  <div class="sidebar-welcome">
    <h2>{{ "dashboard.welcome_title" | translate }}</h2>
    <p>{{ "dashboard.welcome_message" | translate }}</p>
  </div>
  <div class="container">
    <div class="section">
      <div class="section-header" (click)="moveTo('/procedure')">
        <div class="section-title">{{ "menu.procedures" | translate }}</div>
      </div>
      <div class="section-content">
        <mat-nav-list>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/procedure/procedures')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.procedures_tabs.procedures" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/procedure/departments')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.procedures_tabs.departments" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/procedure/labs')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.procedures_tabs.labs" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/procedure/price-list')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.procedures_tabs.price_list" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
        </mat-nav-list>
      </div>
    </div>
    <div class="section">
      <div class="section-header" (click)="moveTo('/users')">
        <div class="section-title">{{ "menu.workers" | translate }}</div>
      </div>
      <div class="section-content">
        <mat-nav-list>
          <a mat-list-item class="list-item" (click)="moveTo('/users/workers')">
            <div class="list-content">
              <span class="text">{{
                "menu.workers_tabs.workers" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a mat-list-item class="list-item" (click)="moveTo('/users/roles')">
            <div class="list-content">
              <span class="text">{{
                "menu.workers_tabs.roles" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/users/candidates')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.workers_tabs.candidates" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
        </mat-nav-list>
      </div>
    </div>
    <div class="section">
      <div class="section-header" (click)="moveTo('/inventory')">
        <div class="section-title">
          {{ "menu.inventory_and_vendors" | translate }}
        </div>
      </div>
      <div class="section-content">
        <mat-nav-list>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/inventory/items')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.inventory_and_vendors_tabs.inventory" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/inventory/purchase_requirements')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.inventory_and_vendors_tabs.purchase_requirements"
                  | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/inventory/vendors')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.inventory_and_vendors_tabs.vendors" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
        </mat-nav-list>
      </div>
    </div>
    <div class="section">
      <div class="section-header" (click)="moveTo('/customers')">
        <div class="section-title">{{ "menu.customers" | translate }}</div>
      </div>
      <div class="section-content">
        <mat-nav-list>
          <a mat-list-item class="list-item" (click)="moveTo('/customers')">
            <div class="list-content">
              <span class="text">{{ "menu.customers" | translate }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a mat-list-item class="list-item" (click)="moveTo('/customers/price-offers')">
            <div class="list-content">
              <span class="text">{{ "menu.customers_tabs.price_offer" | translate }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a mat-list-item class="list-item" (click)="moveTo('/customers/agreements')">
            <div class="list-content">
              <span class="text">{{ "menu.customers_tabs.agreement" | translate }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/customers/price-offer-comments')"
          >
            <div class="list-content">
              <span class="text">{{
                "menu.customers_tabs.price_offer_comments" | translate
              }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
          <a
            mat-list-item
            class="list-item"
            (click)="moveTo('/customers/orders')"
          >
            <div class="list-content">
              <span class="text">{{ 'order.title' | translate }}</span>
              <mat-icon class="arrow-icon">{{
                direction === "ltr" ? "east" : "west"
              }}</mat-icon>
            </div>
          </a>
        </mat-nav-list>
      </div>
    </div>
    <div class="section">
      <div class="section-header" (click)="moveTo('/tests')">
        <div class="section-title">{{ "menu.tests" | translate }}</div>
      </div>
      <div class="section-content"></div>
    </div>
    <div class="section">
      <div class="section-header" (click)="moveTo('/formats')">
        <div class="section-title">{{ "menu.formats" | translate }}</div>
      </div>
      <div class="section-content"></div>
    </div>
    <div class="section">
      <div class="section-header" (click)="moveTo('/reports')">
        <div class="section-title">{{ "menu.reports" | translate }}</div>
      </div>
      <div class="section-content"></div>
    </div>
  </div>
</app-one-column>
