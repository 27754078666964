<div class="container">
  <h2 mat-dialog-title>{{fileDetails?.title}}</h2>

  <mat-dialog-content class="content">
    <p>{{fileDetails?.description}}</p>
    <p>FileType: {{fileDetails?.type}}</p>
    <p>Filename: {{fileDetails?.fileName}}</p>
    <p>{{fileDetails?.uploadedByName}} | {{fileDetails?.uploadedAt | date: 'dd/MM/yyyy'}}</p>
  </mat-dialog-content>

</div>
