import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchBarItem } from '../../models/search-bar-item.model';
import { TextSearchComponent } from '../../components/search-bar/text-search/text-search.component';

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {
  private searchBarFields = new Subject<SearchBarItem[]>();

  setSearchBar(fields: SearchBarItem[]) {
    this.searchBarFields.next(fields);
  }

  get SearchBarTypeChanges$() {
    return this.searchBarFields.asObservable();
  }
}
