<form class="dialog" [formGroup]="formGroup" (ngSubmit)="uploadFile($event)">
  <h2 mat-dialog-title>Upload file</h2>

  <mat-dialog-content class="content">
    <input type="file" (change)="onFileSelected($event)" #fileInput hidden />
    <div
      class="file-button-container"
      (drop)="onFileDropped($event)"
      (dragover)="onDragOver($event)"
    >
      <button
        mat-flat-button
        (drop)="onFileDropped($event)"
        (click)="fileInput.click()"
        color="primary"
        type="button"
        class="file-button"
      >
        <mat-icon>add</mat-icon>
      </button>
      <span
        class="text-danger"
        *ngIf="
          formGroup.get('file')!.errors &&
          formGroup.get('file')!.hasError('fileTooLarge')
        "
        >File is too large</span
      >
      <span>{{ formGroup.get("file")?.value?.name }}</span>
    </div>

    <mat-form-field>
      <input matInput placeholder="Title" formControlName="title" required />
    </mat-form-field>
    <mat-form-field>
      <textarea
        matInput
        placeholder="Description"
        formControlName="description"
      >
      </textarea>
    </mat-form-field>
    <p>
      Only files of {{ allowedTypes.join(", ") }} type upto
      {{ maxSize / (1024 * 1024) }}MB are allowed.
    </p>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <mat-spinner [diameter]="25" *ngIf="isLoading"></mat-spinner>
    <button mat-flat-button color="primary" type="submit" *ngIf="!isLoading">
      Upload
    </button>
    <button mat-button mat-dialog-close type="button" (click)="closeDialog()">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
