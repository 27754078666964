import { Injectable } from '@angular/core';
import { PersistentDataStorageService } from '../storage/persistent-data-storage.service';
import { environment } from '../../../../environments/environment';
import { TemporaryDataStorageService } from '../storage/temporary-data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {

  constructor(private persistentDataStorage: PersistentDataStorageService, private temporaryStorage: TemporaryDataStorageService) { }

  getToken(): string | null {
    try{
      let token = this.temporaryStorage.get(environment.storageKeys.jwtToken);
      if (!token) {
        token = this.persistentDataStorage.get(environment.storageKeys.jwtToken);
      }
      return token;
    } catch (error) {
      return null;
    }
  }

  saveTokenForPersistence(token: string) {
    this.persistentDataStorage.set(environment.storageKeys.jwtToken, token);
    this.saveTokenForSession(token);
  }

  saveTokenForSession(token: string) {
    this.temporaryStorage.set(environment.storageKeys.jwtToken, token);
  }

  removeToken() {
    this.persistentDataStorage.remove(environment.storageKeys.jwtToken);
    this.temporaryStorage.remove(environment.storageKeys.jwtToken);
  }
}
