import { Component } from '@angular/core';
import { MenuItem } from 'src/app/core/interfaces/menu-item.interface';

const MENU_ITEMS: MenuItem[] = [

  {
    name: 'menu.customers',
    icon: '',
    type: 'group',
    route: '/customers',
    isOpen: false,
    children: [
      {
        name: 'menu.customers',
        icon: '',
        route: '/customers',
        type: 'button',
      },
      {
        name: 'menu.customers_tabs.price_offer',
        icon: '',
        route: '/customers/price-offers',
        type: 'button',
      },
      {
        name:'menu.customers_tabs.agreement',
        icon:'',
        route:'/customers/agreements',
        type:'button',
      },
      {
        name: 'menu.customers_tabs.price_offer_comments',
        icon: '',
        route: '/customers/price-offer-comments',
        type: 'button',
      },
      {
        name: 'menu.customers_tabs.orders',
        icon: '',
        route: '/customers/orders',
        type: 'button',
      }
    ]
  }
];

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.css'
})
export class CustomersComponent {
  menuItems: MenuItem[] = MENU_ITEMS;
}
