import { AfterViewInit, Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NotificationService } from 'src/app/features/dashboard/services/notification.service';
import { Notification } from 'src/app/features/dashboard/interfaces/notification.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss']
})

export class NotificationPageComponent implements AfterViewInit {
 

 
  notifications: Notification[] = [];
  isLoading: boolean = false;
  currentPage: number = 0;
  pageSize: number = 15;
  unreadCount!: number;
  totalNotifications!: number;
  constructor(private notificationService: NotificationService,private router: Router, private el: ElementRef, private renderer: Renderer2) {
    this.loadNotifications();
    this.updateUnreadNotificationsCounter()
  }

  ngAfterViewInit() {
    const content = this.el.nativeElement.querySelector('mat-sidenav-content');
    this.renderer.listen(content, 'scroll', (event) => {
      if (content.scrollTop + content.clientHeight + 2 >= content.scrollHeight) {
       
        this.loadMoreNotifications();
      }
    });
  }

  loadNotifications(): void {
    this.isLoading = true;
    this.notificationService.getLastNotifications(this.currentPage, this.pageSize)
      .subscribe(notifications => {
        this.notifications = [...this.notifications, ...notifications.content];
        this.totalNotifications = notifications.totalElements;
        this.isLoading = false;
        setTimeout(() => this.checkAndLoadMore(), 100);
      }, error => {
        console.error(error);
        this.isLoading = false;
      });
  }

  loadMoreNotifications(): void {
    if (this.isLoading || (this.currentPage + 1) * this.pageSize >= this.totalNotifications) {
      return;
    }
    this.currentPage++;
    this.loadNotifications();
  }
  markAsRead(notification: Notification, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
  }
    if (notification.read) {
      return;
    }
    this.notificationService.read(notification.id).subscribe({
      next: () => this.updateUnreadNotificationsCounter(),
      error: (error) => console.log(error),
    });
    notification.read = true;
  }
  updateUnreadNotificationsCounter() {
    this.notificationService.unreadCount().subscribe((count: number) => {
      this.unreadCount= count;
    });
  }
  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
  onNotificationClicked(notification: Notification) {
    if (!notification.read) {
      this.markAsRead(notification);
    }

    if (notification.link) {
      if (notification.queryParams){
      this.router.navigate([decodeURIComponent(notification.link), notification.queryParams]);
      }
      else {
        this.router.navigate([decodeURIComponent(notification.link)]);
      }
    }
  }
  checkAndLoadMore(): void {
    const content = this.el.nativeElement.querySelector('mat-sidenav-content');
    if (content.clientHeight >= content.scrollHeight) {
      this.loadMoreNotifications();
    }
  }
  
}
