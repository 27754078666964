<mat-nav-list>
    <ng-container *ngFor="let item of items">
        <mat-list-item (click)="handleClick(item)" class="top-level-item">
            <div [class.activated]="shouldActivate(item)" class="list-item">
                {{item.name | translate}}
            </div>
        </mat-list-item>
        <ng-container *ngIf="shouldActivate(item) && item.type === 'group'">
            <ng-container *ngTemplateOutlet="renderChildren; context:{ $implicit: item.children, level: 1 }"></ng-container>
        </ng-container>
        <mat-divider></mat-divider>
    </ng-container>

    <ng-template #renderChildren let-children let-level="level">
        <ng-container *ngFor="let child of children">
            <mat-list-item (click)="handleClick(child)" class="nested-item" [ngClass]="'level-' + level">
                <div [class.activated]="shouldActivate(child)" class="list-item child-spacer">
                    <ng-container *ngIf="child.type === 'group'">
                        <mat-icon matListIcon>
                            {{ shouldActivate(child) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                    </ng-container>
                    <ng-container *ngIf="child.type !== 'group'">
                    <mat-icon matListIcon >
                        
                    </mat-icon>
                </ng-container>
                    {{child.name | translate}}
                </div>
            </mat-list-item>
            <ng-container *ngIf="shouldActivate(child) && child.type === 'group'">
                <ng-container *ngTemplateOutlet="renderChildren; context:{ $implicit: child.children, level: level + 1 }"></ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
</mat-nav-list>
