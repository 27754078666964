import { Direction } from '@angular/cdk/bidi';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent  {
  direction: Direction; 
 
  constructor(private router: Router, private languageService: LanguageService) {
    this.direction = this.languageService.getCurrentDirection();
    this.subscribeToLanguageChanges();
  }
  private subscribeToLanguageChanges() {
    this.languageService.directionsChanged$.subscribe((newDirection: Direction) => {
      this.direction = newDirection;
    });
  }
  moveTo(url: string) {
    this.router.navigate([url]);
  }
}
