<div (click)="onFileClick($event)" class="container">
  <button mat-flat-button class="button-container" color="primary" type="button">
    <img *ngIf="file?.type == fileType.IMAGE || file?.type == fileType.GIF" [src]="authenticatedFileUrl" alt="file" class="button-container__image" />
    <div *ngIf="file?.type == fileType.VIDEO" class="video-container">
      <video class="video-container__video" *ngIf="authenticatedFileUrl">
        <source [src]="authenticatedFileUrl + '#t=0.1'" type="video/mp4" />
      </video>
      <mat-icon class="video-container__play">play_circle_outline</mat-icon>
    </div>
    <div *ngIf="file?.type == fileType.EXCEL || file?.type == fileType.OTHER || file?.type == fileType.WORD || file?.type == fileType.PDF" class="button-container__file">
      <fa-icon [icon]="file ? fileMap.get(file!.type)! : faFile" size="6x"></fa-icon>
    </div>
  </button>
  <span class="info-text">
    {{file?.title}} | {{file?.uploadedByName}} | {{ file?.uploadedAt | date: 'dd/MM/yyyy' }}
  </span>
  <div class="container__actions">
    <div class="spacer"></div>
    <button mat-icon-button class="container__info" (click)="onFileInfoClick($event)" type="button">
      <mat-icon>info</mat-icon>
    </button>
    <button mat-icon-button class="container__download" (click)="onFileDownloadClick($event)" type="button">
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>
</div>
