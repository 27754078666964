import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-two-buttons-dialog',
  templateUrl: './two-buttons-dialog.component.html',
  styleUrls: ['./two-buttons-dialog.component.css']
})
export class TwoButtonsDialogComponent {
  constructor(private dialogRef: MatDialogRef<TwoButtonsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }
}

export interface DialogData {
  title: string;
  message: string;
  firstButtonLabel: string;
  secondButtonLabel: string;
}