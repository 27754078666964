import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/features/dashboard/services/notification.service';
import { Notification } from 'src/app/features/dashboard/interfaces/notification.interface';
import { TranslateService } from '@ngx-translate/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  @Output() onNotificationChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() item = false;
  notifications: Notification[] | undefined;
  unreadNotifications: Notification[] | undefined;
  showAllNotifications: boolean = false;
  showLoadMoreButton: boolean = false;
  currentPage: number = 0;
  currentTab: number = 0;


  constructor(private router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService,) {
    this.updateUnreadNotificationsCounter();
    notificationService.getLastFive().subscribe((notifications) => {
      this.notifications = notifications.content
    }
    );
  }
  onTabChange(event: MatTabChangeEvent) {
    this.currentPage = 0;
    if (event.index === 0) {
      this.currentTab = 0
      this.loadNotifications();
    } else if (event.index === 1) {
      this.currentTab = 1
      this.loadUnreadNotifications();
    }
  }
  hideAllNotifications() {
    this.showAllNotifications = false;
  }
  loadUnreadNotifications() {
    const notificationObservable = this.showAllNotifications ? this.notificationService.getUnreadNotifications(this.currentPage, 20) : this.notificationService.getUnreadNotifications(this.currentPage, 5);
    notificationObservable.subscribe((notifications) => {
      this.unreadNotifications = notifications.content;
      this.showLoadMoreButton = notifications.totalElements > (this.showAllNotifications ? 20 : 5);
    });
  }
  loadNotifications() {
    const notificationObservable = this.showAllNotifications ?
      this.notificationService.getLastNotifications() : this.notificationService.getLastFive();
    notificationObservable.subscribe((notifications) => {
      this.showLoadMoreButton = notifications.totalElements > (this.showAllNotifications ? 20 : 5);
      this.notifications = notifications.content
    });
  }

  updateUnreadNotificationsCounter() {
    this.notificationService.unreadCount().subscribe((count: number) => {
      this.onNotificationChanged.emit(count);
    });
  }

  onNotificationClicked(notification: Notification) {
    if (!notification.read) {
      this.setNotificationAsRead(notification);
    }

    if (notification.link) {
      if (notification.queryParams){
      this.router.navigate([decodeURIComponent(notification.link), notification.queryParams]);
      }
      else {
        this.router.navigate([decodeURIComponent(notification.link)]);
      }
    }
  }

  setNotificationAsRead(notification: Notification) {
    this.notificationService.read(notification.id).subscribe({
      next: () => this.updateUnreadNotificationsCounter(),
      error: (error) => console.log(error),
    });
    notification.read = true;
  }

  getNotificationClass(notification: Notification) {
    return notification.read ? 'read' : 'unread';
  }

  parseDate(date: string) {
    let parsedDate = new Date(date);

    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');

    return `${this.getDateString(parsedDate)} ${hours}:${minutes}`;
  }

  getDateString(date: Date) {
    if (this.isToday(date)) {
      return this.translate.instant('header.notifications.today');
    }
    if (this.isYesterday(date)) {
      return this.translate.instant('header.notifications.yesterday');
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  isToday(date: Date) {
    let today = new Date();
    return date.getDay() === today.getDay() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  isYesterday(date: Date) {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getDay() === yesterday.getDay() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear();
  }
  showLessNotifications() {
    this.currentPage = 0
    this.showAllNotifications = false;
    if (this.currentTab === 0) {
      this.loadNotifications();
    }
    else {
      this.loadUnreadNotifications()
    }
  }
  showMoreNotifications() {
    this.router.navigate(['notifications']);
  }
  loadMoreNotifications() {
    this.router.navigate(['notifications']);
  }

}
