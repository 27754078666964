
import { Injectable } from '@angular/core';
import { TemporaryDataStorageService } from './temporary-data-storage.service';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService implements TemporaryDataStorageService {
    set(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    get(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    remove(key: string) {
        sessionStorage.removeItem(key);
    }
}