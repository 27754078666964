import { Injectable, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
    private translate = inject(TranslateService);

    constructor() {
        super();
        this.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPageLabel');
        this.nextPageLabel = this.translate.instant('paginator.nextPage');
        this.previousPageLabel = this.translate.instant('paginator.previousPage');
        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            let start = page * pageSize + 1;
            let end = (page + 1) * pageSize;
            if (end > length) { end = length; }
            return `${start} - ${end} ${this.translate.instant('paginator.of')} ${length}`

        }
    }
}