import { Component } from '@angular/core';
import { MenuItem } from 'src/app/core/interfaces/menu-item.interface';

const MENU_ITEMS: MenuItem[] = [
  {
    name: 'menu.users',
    icon: '',
    route: '/users/workers',
    type: 'group',
    isOpen: false,
    children: [
      {
        name: 'menu.workers_tabs.workers',
        icon: 'engineering',
        route: '/users/workers',
        type: 'group',
        isOpen: false,
        children: [
          {
            name: 'menu.workers_tabs.onboarding_form',
            icon: 'toc',
            route: '/users/workers/onboarding_form',
            type: 'button',

          },
        ],
      },
      {
        name: 'menu.workers_tabs.roles',
        icon: 'admin_panel_settings',
        route: '/users/roles',
        type: 'button'
      },
      {
        name: 'menu.workers_tabs.candidates',
        icon: 'directions_run',
        route: '/users/candidates',
        type: 'button'
      },
    ]
  }
];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {
  menuItems: MenuItem[] = MENU_ITEMS;

}
