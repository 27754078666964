import { Component, EventEmitter, Output } from '@angular/core';
import { SearchComponent } from '../search-component.interface';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.css']
})
export class SelectSearchComponent implements SearchComponent {
  @Output() outputEvent: EventEmitter<string> = new EventEmitter<string>();
  data: any;

  onItemSelected(item: string) {
    this.outputEvent.emit(item);
  }
}