import { Component } from '@angular/core';
import { MenuItem } from 'src/app/core/interfaces/menu-item.interface';

const MENU_ITEMS: MenuItem[] = [
  {
    name: 'menu.inventory_and_vendors',
    icon: '',
    type: 'group',
    route: '/inventory/vendors',
    isOpen: false,
    children: [
      {
        name: 'menu.inventory_and_vendors_tabs.inventory',
        icon: 'inventory',
        route: '/inventory/items',
        type: 'button',
      },
      {
        name: 'menu.inventory_and_vendors_tabs.purchase_requirements',
        icon: 'request_page',
        route: '/inventory/purchase_requirements',
        type: 'button',
      },
      {
        name: 'menu.inventory_and_vendors_tabs.vendors',
        icon: 'construction',
        type: 'group',
        route: '/inventory/vendors',
        isOpen: false,
        children: [
          {
            name: 'inventory_and_vendors.vendors.mismatches',
            icon: 'view_list',
            route: '/inventory/vendors/mismatches',
            type: 'button',
          },
          {
            name: 'inventory_and_vendors.vendors.evaluations',
            icon: 'view_list',
            route: '/inventory/vendors/evaluations',
            type: 'button',
          },
        ],
      },
    ],
  },
];

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
})
export class InventoryComponent {
  menuItems: MenuItem[] = MENU_ITEMS;
}
