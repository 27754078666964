import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { OneColumnComponent } from './layouts/one-column/one-column.component';
import { SearchBarComponent } from './search-bar/search-bar/search-bar.component';
import { TextSearchComponent } from './search-bar/text-search/text-search.component';
import { SelectSearchComponent } from './search-bar/select-search/select-search.component';
import { SearchBarDirective } from './search-bar/search-bar.directive';
import { DateRangeSearchComponent } from './search-bar/date-range-search/date-range-search.component';
import { TwoButtonsDialogComponent } from './two-buttons-dialog/two-buttons-dialog.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { NotificationsComponent } from './notifications/notifications.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FileDetailsDialogComponent } from './file-details-dialog/file-details-dialog.component';
import { FilePreviewDialogComponent } from './file-preview-dialog/file-preview-dialog.component';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { FileComponent } from './file/file.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NotificationPageComponent } from './notification-page/notification-page.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    OneColumnComponent,
    SearchBarComponent,
    SearchBarDirective,
    TextSearchComponent,
    SelectSearchComponent,
    DateRangeSearchComponent,
    TwoButtonsDialogComponent,
    UnauthorizedPageComponent,
    NotificationsComponent,
    FileUploadDialogComponent,
    FileComponent,
    FilePreviewDialogComponent,
    FileDetailsDialogComponent,
    NotificationPageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatBadgeModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    FontAwesomeModule,
    PdfViewerModule,
    MatProgressSpinnerModule
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    OneColumnComponent,
    SearchBarComponent,
    SearchBarDirective,
    TextSearchComponent,
    SelectSearchComponent,
    DateRangeSearchComponent,
    FileUploadDialogComponent,
    FileComponent
  ],
})
export class ComponentsModule { }
