import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AuthComponent } from './features/auth/auth.component';

import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { UsersComponent } from './features/users/users.component';
import { InventoryComponent } from './features/inventory/inventory.component';
import { authGuard } from './core/guards/auth.guard';
import { UnauthorizedPageComponent } from './core/components/unauthorized-page/unauthorized-page.component';
import { ProcedureComponent } from './features/procedure/procedure.component';
import { NotificationPageComponent } from './core/components/notification-page/notification-page.component';
import { CustomersComponent } from './features/customers/customers.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthComponent,
    loadChildren: () => import('./features/auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: 'notifications',
    component:NotificationPageComponent,
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    component: DashboardComponent,
    loadChildren: () => import('./features/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
    data: { authority: 'ALL' }
  },
  {
    path: 'users',
    component: UsersComponent,
    loadChildren: () => import('./features/users/users.module')
      .then(m => m.UsersModule),
  },
  {
    path: 'inventory',
    component: InventoryComponent,
    loadChildren: () => import('./features/inventory/inventory.module')
      .then(m => m.InventoryModule),
  },
  {
    path: 'procedure',
    component: ProcedureComponent,
    loadChildren: () => import('./features/procedure/procedure.module')
       .then(m => m.ProcedureModule),
  },
  {
    path: 'customers',
    component: CustomersComponent,
    loadChildren: () => import('./features/customers/customers.module')
       .then(m => m.CustomersModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./features/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent,
  },
  { path: '', redirectTo: 'notfound', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
