import { Component, Input } from '@angular/core';
import { MenuItem } from '../../interfaces/menu-item.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {
  @Input() items?: MenuItem[] = [];
  currentItem?: MenuItem = this.items && this.items.length > 0 ? this.items[0] : undefined;

  constructor(private router: Router) { }

  handleClick(item: MenuItem): void {
    if (item.type === 'group') {
      item.isOpen = !item.isOpen;
    }
    this.router.navigate([item.route]);
  }
  shouldActivate(item: MenuItem): boolean {
    if (this.router.url === '/' && !this.currentItem) {
      return false;
    }

    if (item.route && this.router.url.includes(item.route)) {
      return true;
    }

    if (item.type === 'group' && item.children) {
      return item.children.some(child => this.shouldActivate(child));
    }

    return false;
  }

}
