import { inject } from "@angular/core";
import { SearchBarItem } from "src/app/core/models/search-bar-item.model";
import { SearchBarService } from "src/app/core/services/search-bar/search-bar.service";
import { ToolbarTitleService } from "src/app/core/services/toolbar-title.service";
import { environment } from "src/environments/environment";

interface Data {
    toolbarTitle?: string;
    searchBarItems?: SearchBarItem[];
}

export abstract class PageWithToolbar {
    private toolbarTitleService = inject(ToolbarTitleService);
    private searchBarService = inject(SearchBarService);
    
    constructor(data?: Data) {
        this.changeTitle(data?.toolbarTitle ?? environment.appName);
        this.setSearchBar(data?.searchBarItems ?? []);
    }

    protected changeTitle(title: string) {
        this.toolbarTitleService.setToolbarTitle(title);
    }

    protected setSearchBar(items: SearchBarItem[]) {
        this.searchBarService.setSearchBar(items);
    }

}