const baseUrl = 'https://api.qtest.co.il';

export const environment = {
  production: false,
  appName: 'header.app_name_debug',
  apiUrls: {
    baseUrl: baseUrl,
    auth: {
      login: baseUrl + '/auth/login',
      validate: baseUrl + '/auth/validate',
      authorities: baseUrl + '/auth/authorities',
    },
    users: {
      users: 'users',
      me: `${baseUrl}/users/me`,
      permissions: {
        allAuthorities: baseUrl + '/permissions/authorities',
        allRoles: baseUrl + '/permissions/roles',
        role: baseUrl + '/permissions/role',
        roleId: function (roleId: string) {
          return baseUrl + '/permissions/role/' + roleId;
        },
        allPublishers: baseUrl + '/permissions/publishers',
        publishersByRole: function (roleId: string) {
          return baseUrl + '/permissions/role/' + roleId + '/publishers';
        },
        subscribePublisher: function (roleId: string) {
          return (
            baseUrl + '/permissions/role/' + roleId + '/publishers/subscribe'
          );
        },
        unsubscribePublisher: function (roleId: string) {
          return (
            baseUrl + '/permissions/role/' + roleId + '/publishers/unsubscribe'
          );
        },
      },
      workers: {
        workers: baseUrl + '/workers',
        getAllWorkers: baseUrl + '/workers/all',
        uploadWorkerDocument:(workerId:number) => baseUrl + `/workers/${workerId}/documents`,
        uploadWorkerQualification:(workerId: number, procedureId:number)=> baseUrl + `/workers/${workerId}/qualifications/${procedureId}/upload`,
        createQualification:(workerId: number, procedureId:number) =>baseUrl + `/workers/${workerId}/qualifications/${procedureId}`,
        signQualification:(workerId: number, procedureId:number) => baseUrl +`/workers/${workerId}/qualifications/${procedureId}/sign`,
        updateWorkerDetail:(workerId:number) => baseUrl + `/workers/${workerId}/details`,
        updateWorkerRolesAndAuthorities:(workerId: number) => baseUrl + `/workers/${workerId}/roles-authorities`,
        workerId: function (workerId: number) {
          return baseUrl + '/workers/worker/' + workerId;
        },
        changeStatus:(id:number)=> `${baseUrl}/workers/${id}/status`
      },
      onboardingForms: {
        create: baseUrl + '/onboarding-forms',
        getAll: baseUrl + '/onboarding-forms',
        changeState: (id: string, state: string) =>
          `${baseUrl}/onboarding-forms/${id}/change-state?state=${state}`,
      },
      candidates: {
        candidates: baseUrl + '/candidates',
        all: (page: number, size: number) =>
          `${baseUrl}/candidates/?page=${page}&size=${size}`,
        getCandidateById: (candidateId: string) =>
          `${baseUrl}/candidates/${candidateId}`,
        updateCandidate: (candidateId: string) =>
          `${baseUrl}/candidates/${candidateId}`,
        createOrUpdateCandidate: baseUrl + '/candidates',
        uploadResume: (id: string) =>
          `${baseUrl}/candidates/${id}/upload-resume`,
        byFullName: (fullName: string, page: number, size: number) =>
          `${baseUrl}/candidates/search?candidateFullName=${fullName}&page=${page}&size=${size}`,
        byPosition: (position: string, page: number, size: number) =>
          `${baseUrl}/candidates/search?candidatePosition=${position}&page=${page}&size=${size}`,
        byDate: (
          startDate: string,
          endDate: string,
          page: number,
          size: number,
        ) =>
          `${baseUrl}/candidates/search?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`,
        byPhoneNumber: (phoneNumber: string, page: number, size: number) =>
          `${baseUrl}/candidates/search?candidatePhoneNumber=${phoneNumber}&page=${page}&size=${size}`,
        addCommentToCandidate: (candidateId: string) =>
          `${baseUrl}/candidates/${candidateId}/comments`,
      },
    },
    customers: {
      customers: baseUrl + '/customers',
      getAll: baseUrl + '/customers',
      changeState: (id: number, state: string) =>
        `${baseUrl}/customers/${id}/change-state?state=${state}`,
      getById: (id: number) => `${baseUrl}/customers/${id}`,
      updateCustomer: (id: number) => `${baseUrl}/customers/${id}`,
      addContact: (id: number) => `${baseUrl}/customers/${id}/addContact`,
      addComment: (customerId: number, commentIndex: number) => `${baseUrl}/customers/${customerId}/addComment/${commentIndex}`,
      priceOfferComments: {
        all: `${baseUrl}/priceOfferComments`,
        create: `${baseUrl}/priceOfferComments`,
        update: (id: string) => `${baseUrl}/priceOfferComments/${id}`,
        getById: (id: string) => `${baseUrl}/priceOfferComments/${id}`,
        delete: (id: string) => `${baseUrl}/priceOfferComments/${id}`,
        updatePlaces: `${baseUrl}/priceOfferComments/updatePlaces`,
        search: `${baseUrl}/priceOfferComments/search`,
      },
      priceOffers: {
        create: `${baseUrl}/customers/priceOffers`,
        getAll: `${baseUrl}/customers/priceOffers`,
        search:`${baseUrl}/customers/priceOffers/search`,
        getById: (id: number) => `${baseUrl}/customers/priceOffers/${id}`,
        sendToCustomer: (id: number) =>
          `${baseUrl}/customers/priceOffers/${id}/send`,
        cancelPriceOffer: (id: number) => `${baseUrl}/customers/priceOffers/${id}/cancel`,
        draftPriceOffer: (id: number) => `${baseUrl}/customers/priceOffers/${id}/draft`,
        getSigned: `${baseUrl}/customers/priceOffers/signed`,
        preview: (id: number) => `${baseUrl}/customers/priceOffers/${id}/preview`,
        updateComments: (id: number) => `${baseUrl}/customers/priceOffers/${id}/comments`,
        updatePriceList: (id: number) => `${baseUrl}/customers/priceOffers/${id}/priceList`,
        unsigned: (id: number) =>  `${baseUrl}/customers/priceOffers/${id}/unsigned`,
        signed: (id: number) =>  `${baseUrl}/customers/priceOffers/${id}/signed`,
      },
      orders: {
        create: (agreementId: number) => `${baseUrl}/customers/orders/agreement/${agreementId}`,
        update: (orderId: string) => `${baseUrl}/customers/orders/${orderId}`,
        max: `${baseUrl}/customers/orders/maxOrder`,
        assign:`${baseUrl}/customers/orders/assign`,
        abort:`${baseUrl}/customers/orders/abort`,
        search: `${baseUrl}/customers/orders/search`,
        getAll:`${baseUrl}/customers/orders`,
        export: (orderId: string) => `${baseUrl}/customers/orders/${orderId}/export`,
        getOrder: (orderId:string) => `${baseUrl}/customers/orders/${orderId}`,
      },
      agreements: {
        all: (page: number, size: number) => `${baseUrl}/customers/agreements?page=${page}&size=${size}`,
        getById: (id: number) => `${baseUrl}/customers/agreements/${id}`,
        //TODO: implement searching routs
        create: `${baseUrl}/customers/agreements`,
        updateAgreementDetails: (id: number) => `${baseUrl}/customers/agreements/${id}/agreementDetails`,
        updateProjectDetails: (id: number) => `${baseUrl}/customers/agreements/${id}/projectDetails`,
        updatePriceList: (id: number) => `${baseUrl}/customers/agreements/${id}/priceList`,
        updatePaymentsDetails: (id: number) => `${baseUrl}/customers/agreements/${id}/paymentsDetails`,
        updateOrderingDetails: (id: number) => `${baseUrl}/customers/agreements/${id}/orderingDetails`,
        updateOrderingSurvey: (id: number) => `${baseUrl}/customers/agreements/${id}/orderingSurvey`,
        cancelAppointment: (id: number) => `${baseUrl}/customers/agreements/${id}/cancel-appointment`,
        cancelAgreement:(id:number) => `${baseUrl}/customers/agreements/${id}/cancel`,
        draftAgreement:(id:number)=> `${baseUrl}/customers/agreements/${id}/draft`,
        sendToCustomer: (id: number) => `${baseUrl}/customers/agreements/${id}/send`,
        preview: (id: number) => `${baseUrl}/customers/agreements/${id}/preview`,
        unsigned: (id: number) =>  `${baseUrl}/customers/agreements/${id}/unsigned`,
        signed: (id: number) =>  `${baseUrl}/customers/agreements/${id}/signed`,
      }
    },
    notification: {
      notifications: function (page: number, size: number) {
        return baseUrl + '/notifications?page=' + page + '&size=' + size;
      },
      unreadNotifications: function (page: number, size: number) {
        return baseUrl + '/notifications/unread?page=' + page + '&size=' + size;
      },
      read: function (id: string) {
        return baseUrl + '/notifications/' + id + '/read';
      },
      unread: function (id: string) {
        return baseUrl + '/notifications/' + id + '/unread';
      },
      unreadCount: baseUrl + '/notifications/unread/count',
    },
    inventory: {
      vendors: {
        vendors: baseUrl + '/vendors',
        mismatches: {
          all: (page: number, size: number) =>
            `${baseUrl}/vendor-mismatches/?page=${page}&size=${size}`,
          update: (id: string) => `${baseUrl}/vendor-mismatches/${id}`,
          create: `${baseUrl}/vendor-mismatches/`,
          byVendorId: (vendorId: string, page: number, size: number) =>
            `${baseUrl}/vendor-mismatches/get-by-vendor?vendorId=${vendorId}&page=${page}&size=${size}`,
          byStatus: (status: string, page: number, size: number) =>
            `${baseUrl}/vendor-mismatches/get-by-status?status=${status}&page=${page}&size=${size}`,
          byVendorName: (vendorName: string, page: number, size: number) =>
            `${baseUrl}/vendor-mismatches/get-by-vendor-name?vendorName=${vendorName}&page=${page}&size=${size}`,
          byId: (id: string) => `${baseUrl}/vendor-mismatches/${id}`,
        },
        evaluations: {
          create: `${baseUrl}/vendor-evaluations/`,
          update: (id: string) => `${baseUrl}/vendor-evaluations/${id}`,
          all: (page: number, size: number) =>
            `${baseUrl}/vendor-evaluations/?page=${page}&size=${size}`,
          byId: (id: string) => `${baseUrl}/vendor-evaluations/${id}`,
          byVendorId: (vendorId: string, page: number, size: number) =>
            `${baseUrl}/vendor-evaluations/get-by-vendor-id?vendorId=${vendorId}&page=${page}&size=${size}`,
          byVendorName: (vendorName: string, page: number, size: number) =>
            `${baseUrl}/vendor-evaluations/search-by-vendor-name?vendorName=${vendorName}&page=${page}&size=${size}`,
          byDateRange: (
            startDate: string,
            endDate: string,
            page: number,
            size: number,
          ) =>
            `${baseUrl}/vendor-evaluations/search-by-date-range?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`,
          allAbove: (minTotalRank: number, page: number, size: number) =>
            `${baseUrl}/vendor-evaluations/get-with-min-total-rank?minTotalRank=${minTotalRank}&page=${page}&size=${size}`,
          allUnder: (maxTotalRank: number, page: number, size: number) =>
            `${baseUrl}/vendor-evaluations/get-with-max-total-rank?maxTotalRank=${maxTotalRank}&page=${page}&size=${size}`,
        },
      },
      purchaseRequirement: {
        create: `${baseUrl}/inventory/purchase-requirements`,
        uploadPriceOffer: (id: number) =>
          `${baseUrl}/inventory/purchase-requirements/${id}/upload-price-offer`,
        sendToApproval: (id: number) =>
          `${baseUrl}/inventory/purchase-requirements/${id}/send-to-approval`,
        approve: (id: number, priceOfferIndex: number) =>
          `${baseUrl}/inventory/purchase-requirements/${id}/approve?priceOfferIndex=${priceOfferIndex}`,
        deny: (id: number, priceOfferIndex: number) =>
          `${baseUrl}/inventory/purchase-requirements/${id}/deny?priceOfferIndex=${priceOfferIndex}`,
        changeState: (id: number, state: string) =>
          `${baseUrl}/inventory/purchase-requirements/${id}/change-state?state=${state}`,
        updatePurchaseRequirement: (id: number) =>
          `${baseUrl}/inventory/purchase-requirements/${id}`,
        all: (page: number, size: number) =>
          `${baseUrl}/inventory/purchase-requirements?page=${page}&size=${size}`,
        get: (id: number) => `${baseUrl}/inventory/purchase-requirements/${id}`,
      },
      equipmentItem: {
        create: `${baseUrl}/inventory/equipment-items`,
        getById: (id: number) => `${baseUrl}/inventory/equipment-items/${id}`,
        updateDetails: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/details`,
        saveMaintenancePlan: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/maintenance-plan`,
        getAll: (page: number, size: number) =>
          `${baseUrl}/inventory/equipment-items?page=${page}&size=${size}`,
        uploadValidationFile: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/validations/files`,
        uploadCalibrationFile: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/calibrations/files`,
        createCalibration: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/calibrations`,
        createValidation: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/validations`,
        moveToLab: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/move-to-lab`,
        replaceWorker: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/replace-worker`,
        sign: (id: number) => `${baseUrl}/inventory/equipment-items/${id}/sign`,
        approve: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/approve`,
        search: {
          searchById: (id: number, page: number, size: number) =>
            `${baseUrl}/inventory/equipment-items/search-by-id?id=${id}&page=${page}&size=${size}`,
          searchByItemName: (itemName: string, page: number, size: number) =>
            `${baseUrl}/inventory/equipment-items/search-by-item-name?itemName=${itemName}&page=${page}&size=${size}`,
          searchBySerialCode: (
            serialCode: string,
            page: number,
            size: number,
          ) =>
            `${baseUrl}/inventory/equipment-items/search-by-serial-code?serialCode=${serialCode}&page=${page}&size=${size}`,
          searchByLab: (labId: string, page: number, size: number) =>
            `${baseUrl}/inventory/equipment-items/search-by-lab?labId=${labId}&page=${page}&size=${size}`,
          searchByWorker: (workerName: string, page: number, size: number) =>
            `${baseUrl}/inventory/equipment-items/search-by-worker?workerName=${workerName}&page=${page}&size=${size}`,
          searchByState: (state: string, page: number, size: number) =>
            `${baseUrl}/inventory/equipment-items/search-by-state?state=${state}&page=${page}&size=${size}`,
        },
        getNextIdForSerialCode: `${baseUrl}/inventory/equipment-items/next-serial-code`,
        reportBroken: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/report-broken`,
        reportStolen: (id: number) =>
          `${baseUrl}/inventory/equipment-items/${id}/report-stolen`,
      },
    },
    procedures: {
      procedures: {
        procedures: baseUrl + '/procedures',
        getAllDomains: baseUrl + '/procedures/domains',
        findByDomain: (domain: string, page: number, size: number) =>
          `${baseUrl}/procedures/domains/${domain}?page=${page}&size=${size}`,
        create: baseUrl + '/procedures',
        update: (id: string) => `${baseUrl}/procedures/${id}`,
        get: (id: string) => `${baseUrl}/procedures/${id}`,
        getAll: baseUrl+'/procedures/all',
        updateEdition: (id: string) => `${baseUrl}/procedures/editions/${id}`,
        getEditions: (id: string) => `${baseUrl}/procedures/edition/${id}`,
        addCommentToEdition: (procedureId: string, editionId: string) =>
          `${baseUrl}/procedures/editions/${procedureId}/editions/${editionId}/comments`,
      },
      departments: {
        departments: baseUrl + '/departments',
        departmentId: function (departmentId: number) {
          return baseUrl + '/departments/' + departmentId;
        },
        searchByName: (name: string, page: number, size: number) =>
          `${baseUrl}/departments/search-by-name?name=${name}&page=${page}&size=${size}`,
      },
      labs: {
        list: baseUrl + '/labs',
        byId: (labId: number) => baseUrl + '/labs/' + labId,
      },
      priceList: {
        all: `${baseUrl}/priceList/all`,
        allByPage: (filterActive: boolean, page: number, size: number) =>
          `${baseUrl}/priceList?page=${page}&size=${size}&active=${
            filterActive ? 'TRUE' : 'FALSE'
          }`,
        create: `${baseUrl}/priceList`,
        update: (id: string) => `${baseUrl}/priceList/${id}`,
        byId: (id: string) => `${baseUrl}/priceList/${id}`,
        searchByCatalogNumber: (
          catalogNumber: string,
          filterActive: boolean,
          page: number,
          size: number,
        ) =>
          `${baseUrl}/priceList/search?catalogNumber=${catalogNumber}&page=${page}&size=${size}&active=${
            filterActive ? 'TRUE' : 'FALSE'
          }`,
        searchByStandard: (
          standard: string,
          filterActive: boolean,
          page: number,
          size: number,
        ) =>
          `${baseUrl}/priceList/search?standard=${standard}&page=${page}&size=${size}&active=${
            filterActive ? 'TRUE' : 'FALSE'
          }`,
        searchByDepartmentName: (
          departmentName: string,
          filterActive: boolean,
          page: number,
          size: number,
        ) =>
          `${baseUrl}/priceList/search?departmentName=${departmentName}&page=${page}&size=${size}&active=${
            filterActive ? 'TRUE' : 'FALSE'
          }`,
        findNextIdForDepartment: (departmentNumber: number) =>
          `${baseUrl}/priceList/next-id?departmentId=${departmentNumber}`,
        existsSerialCode: `${baseUrl}/priceList/exists-serial-code`,
      },
      priceListProgram: {
        getAllPrograms: (page: number, size: number) =>
          `${baseUrl}/priceList/programs?page=${page}&size=${size}`,
        search: (name: string, page: number, size: number) =>
          `${baseUrl}/priceList/programs/search?name=${name}page=${page}&size=${size}`,
        getById: (id: string) => `${baseUrl}/priceList/programs/${id}`,
        create: `${baseUrl}/priceList/programs`,
        update: (id: string) => `${baseUrl}/priceList/programs/${id}`,
        names: `${baseUrl}/priceList/programs/names`,
      },
    },
    files: {
      files: 'files',
      upload: 'upload',
    },
  },
  storageKeys: {
    jwtToken: 'jwt',
  },
};

export const generateTokenHeader = (token: string) => {
  return {
    headers: { Authorization: 'Bearer ' + token },
  };
};
