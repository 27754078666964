import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { LocalStorageService } from './core/services/storage/local-storage.service';
import { PersistentDataStorageService } from './core/services/storage/persistent-data-storage.service';
import { TemporaryDataStorageService } from './core/services/storage/temporary-data-storage.service';
import { SessionStorageService } from './core/services/storage/session-storage.service';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { ComponentsModule } from './core/components/components.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from './core/language/MatPaginatorIntlCro';
import { StarRatingModule } from 'angular-star-rating';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({ declarations: [
        AppComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatSelectModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatTableModule,
        ComponentsModule,
        StarRatingModule.forRoot(),
        FontAwesomeModule], providers: [
        { provide: PersistentDataStorageService, useClass: LocalStorageService },
        { provide: TemporaryDataStorageService, useClass: SessionStorageService },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
