import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../../features/auth/services/auth.service';
import { TokenManagerService } from '../services/token-manager/token-manager.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const tokenManager = inject(TokenManagerService);
  const router = inject(Router);
  const authority = route.data['authority'];

  return new Promise((resolve, reject) => {
    const token = tokenManager.getToken();
    if (token) {
      authService.validateToken(token!, authority).subscribe({
        next: (la) => {
          if (la) {
            resolve(true);
          } else {
            router.navigate(['/unauthorized'])
            resolve(false);
          }
        },
        error: (_) => {
          router.navigate(['/unauthorized'])
          resolve(false);
        }
      });
    } else {
      router.navigate(['/auth/login'])
      resolve(false);
    }
  });
};
