import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService, Languages } from './core/services/language.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PageWithToolbar } from './core/components/toolbar/page-with-toolbar.abstract';
import { DOCUMENT } from '@angular/common';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AppComponent extends PageWithToolbar implements OnInit {

  constructor(private translate: TranslateService, private languageService: LanguageService, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {
    super();
    this.setLanguagesAndSetDefault();
  }
  ngOnInit(): void {
    const currentTheme = localStorage.getItem('activeTheme') || 'theme-light';
    this.renderer.setAttribute(this.document.body, 'class', currentTheme);
  }

  setLanguagesAndSetDefault() {
    const language = localStorage.getItem('locale') ?? Languages.en;
    this.translate.addLangs(Object.values(Languages));
    this.translate.setDefaultLang(language);
    this.languageService.setLanguage(language as Languages);
  }

}
