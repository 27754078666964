<mat-tab-group (click)="$event.stopPropagation()" class="notification-tabs" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="{{ 'header.notifications.all' | translate }}" class="notification-tab">
        <ng-template matTabContent>
            <div class="notification">
                <div class="no-notification">
                <span  *ngIf="notifications && notifications.length === 0" class="span-font">{{ "header.notifications.no_notifications" | translate }}</span>
            </div>
                <ng-container *ngIf="notifications && notifications.length > 0">
                    <ng-container *ngFor="let notification of notifications">
                        <button mat-menu-item [ngClass]="getNotificationClass(notification)" (click)="onNotificationClicked(notification)" class="notification-item">
                            <p class="title">{{notification.title}}</p>
                            <p class="message">{{notification.message}}</p>
                            <p class="date">{{parseDate(notification.createdAt)}}</p>
                        </button>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <div class="notification-buttons">
                        <button mat-button color="primary" *ngIf="showLoadMoreButton && showAllNotifications" (click)="loadMoreNotifications()">{{ 'header.notifications.load_more' | translate }}</button>
                        <button mat-button  color ="accent"*ngIf="showAllNotifications" (click)="showLessNotifications()">{{ 'header.notifications.show_less' | translate }}</button>
                    </div>
                    <button mat-button color="primary" *ngIf="!showAllNotifications" (click)="showMoreNotifications()">{{ 'header.notifications.show_more' | translate }}</button>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>

    <mat-tab label="{{ 'header.notifications.unread' | translate }}" class="notification-tab">
        <ng-template matTabContent>
            <div class="notification">
                <div class="no-notification">
                <span *ngIf="unreadNotifications && unreadNotifications.length === 0"  class="span-font">{{ "header.notifications.no_unread_notifications" | translate }}</span>
                </div>
                <ng-container *ngIf="unreadNotifications && unreadNotifications.length > 0">
                    <ng-container *ngFor="let notification of unreadNotifications">
                        <button mat-menu-item [ngClass]="getNotificationClass(notification)" (click)="onNotificationClicked(notification)" class="notification-item">
                            <p class="title">{{notification.title}}</p>
                            <p class="message">{{notification.message}}</p>
                            <p class="date">{{parseDate(notification.createdAt)}}</p>
                        </button>
                        <mat-divider></mat-divider>
                    </ng-container>
                    <div class="notification-buttons">
                        <button mat-button color="primary" *ngIf="showLoadMoreButton && showAllNotifications" (click)="loadMoreNotifications()">{{ 'header.notifications.load_more' | translate }}</button>
                        <button mat-button  color ="accent" *ngIf="showAllNotifications" (click)="showLessNotifications()">{{ 'header.notifications.show_less' | translate }}</button>
                    </div>
                    <button mat-button color="primary" *ngIf="!showAllNotifications" (click)="showMoreNotifications()">{{ 'header.notifications.show_more' | translate }}</button>
                </ng-container>      
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
