<div class="container">
  <h2 mat-dialog-title>File Preview</h2>

  <mat-dialog-content class="content">
    <video *ngIf="fileDetails?.type == fileType.VIDEO" controls class="content__video">
      <source [src]="authenticatedFileUrl" type="video/mp4" />
    </video>
    <img *ngIf="fileDetails?.type == fileType.IMAGE || fileDetails?.type == fileType.GIF" [src]="authenticatedFileUrl"
      alt="file" class="content__image" />
    <pdf-viewer *ngIf="fileDetails?.type == fileType.PDF" [src]="authenticatedFileUrl" class="content__pdf"
      [render-text]="true" [original-size]="false"></pdf-viewer>
  </mat-dialog-content>
</div>
