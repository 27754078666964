import { Component, Input, ViewChild } from '@angular/core';
import { Direction } from "@angular/cdk/bidi";
import { MatSidenav } from '@angular/material/sidenav';
import { LanguageService } from '../../../services/language.service';
import { MenuItem } from 'src/app/core/interfaces/menu-item.interface';

@Component({
  selector: 'app-one-column',
  templateUrl: './one-column.component.html',
  styleUrls: ['./one-column.component.css']
})
export class OneColumnComponent {
  @Input() menuItems?: MenuItem[];
  @Input() showMenu: boolean = true;
  @ViewChild('sidenav') sidenav!: MatSidenav;
  direction: Direction;

  constructor(private languageService: LanguageService) {
    this.direction = languageService.getCurrentDirection();
    this.subscribeToLanguageChanges();
  }

  private subscribeToLanguageChanges() {
    this.languageService.directionsChanged$.subscribe((newDirection: Direction) => {
      this.direction = newDirection;
    });
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  setDirection(newDirection: Direction) {
    this.direction = newDirection;
  }
}
