import { Injectable } from '@angular/core';
import { PersistentDataStorageService } from './persistent-data-storage.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService implements PersistentDataStorageService {
    set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    get(key: string): string | null {
        return localStorage.getItem(key);
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}