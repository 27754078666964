import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenManagerService } from 'src/app/core/services/token-manager/token-manager.service';
import { environment, generateTokenHeader } from '../../../../environments/environment';
import { Notification } from '../interfaces/notification.interface';
import { Page } from 'src/app/core/interfaces/page.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private tokenManager: TokenManagerService) {

  }

  unreadCount() {
    return this.http.get<number>(environment.apiUrls.notification.unreadCount, generateTokenHeader(this.tokenManager.getToken()!));
  }

  getLastFive(page: number = 0, pageSize: number = 5) {
    return this.http.get<Page<Notification>>(environment.apiUrls.notification.notifications(page, pageSize), generateTokenHeader(this.tokenManager.getToken()!));
  }
  getLastNotifications(page: number = 0, pageSize: number = 20) {
    return this.http.get<Page<Notification>>(
      environment.apiUrls.notification.notifications(page, pageSize),
      generateTokenHeader(this.tokenManager.getToken()!)
    );
  }
  

  getUnreadNotifications(page: number = 0, pageSize: number = 20) {
    return this.http.get<Page<Notification>>(environment.apiUrls.notification.unreadNotifications(page, pageSize), generateTokenHeader(this.tokenManager.getToken()!));
  }

  read(id: string) {
    return this.http.put<void>(environment.apiUrls.notification.read(id), {}, generateTokenHeader(this.tokenManager.getToken()!));
  }

  unread(id: string) {
    return this.http.put<void>(environment.apiUrls.notification.unread(id), {}, generateTokenHeader(this.tokenManager.getToken()!));
  }
}
