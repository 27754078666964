import { Component, Output, EventEmitter, Input, ViewChild, Inject, Renderer2 } from '@angular/core';
import { TokenManagerService } from '../../services/token-manager/token-manager.service';
import { Router } from '@angular/router';
import { ToolbarTitleService } from '../../services/toolbar-title.service';
import { NotificationService } from 'src/app/features/dashboard/services/notification.service';
import { NotificationsComponent } from '../notifications/notifications.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService, Languages } from '../../services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() showMenuButton: boolean = true;
  @Output() menuButtonClicked = new EventEmitter<boolean>();
  @ViewChild(NotificationsComponent) private notificationsComponent: NotificationsComponent | undefined;
  title: string = '';
  unreadNotificationsCount: number = 0;
  isDarkMode = false;
  selectedLanguage!: string;

  constructor(private router: Router,
    private tokenManager: TokenManagerService,
    private toolbarTitleService: ToolbarTitleService,
    private notificationService: NotificationService,
    private translate: TranslateService, private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document, private renderer: Renderer2
    ) {
    const activeTheme = localStorage.getItem('activeTheme');
    this.isDarkMode = activeTheme !== null && activeTheme !== undefined && activeTheme !== 'theme-light';
    this.selectedLanguage = localStorage.getItem('locale') ?? Languages.en;
    this.listenToToolbarTitleChange();
  }

  updateNotificationCounter(counter: number) {
    this.unreadNotificationsCount = counter;
  }
  onMenuClosed() {
    if (this.notificationsComponent) {
      this.notificationsComponent.hideAllNotifications();
    }
  }
  onMenuButtonClicked() {
    this.menuButtonClicked.emit(true);
  }

  onHomeButtonClicked() {
    this.router.navigate(['/']);
  }

  logout() {
    this.tokenManager.removeToken();
    this.router.navigate(['/auth/login']);
  }

  listenToToolbarTitleChange() {
    this.toolbarTitleService.toolbarTitle$.subscribe((title: string) => {
      this.title = title;
    });
  }

onDarkModeToggled() {
  this.isDarkMode = !this.isDarkMode;
  const currentTheme = this.isDarkMode ? 'theme-dark' : 'theme-light';
  this.renderer.setAttribute(this.document.body, 'class', currentTheme);
  localStorage.setItem('activeTheme', currentTheme);
}

  onDarkModeSwitched(event : any) {
    const checked = event.value
      const currentTheme= checked ? 'theme-dark' : 'theme-light';
     this.renderer.setAttribute(this.document.body, 'class', currentTheme);
     localStorage.setItem('activeTheme', currentTheme);
   }
   handleLanguageChange(lang: string) {
    const selectedLanguage = lang;
    if (selectedLanguage === Languages.en) {
      localStorage.setItem('locale', Languages.en)
      this.translate.addLangs(Object.values(Languages));
      this.translate.setDefaultLang(Languages.en);
      this.languageService.setLanguage(Languages.en);
    }
    else {
      localStorage.setItem('locale', Languages.he)
      this.translate.addLangs(Object.values(Languages));
      this.translate.setDefaultLang(Languages.he);
      this.languageService.setLanguage(Languages.he);

    }
  }
  }

