import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appSearchBar]'
})
export class SearchBarDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
