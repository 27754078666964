<div *ngIf="show" class="search-bar-container">
    <span class="search-bar-title"></span>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="select-field">
        <mat-select (selectionChange)="onSearchBarTypeChange($event.value)" [placeholder]="'header.search_by' | translate">
            <mat-option *ngFor="let item of searchBarItems" [value]="item">
                {{item.fieldName | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <ng-template appSearchBar></ng-template>
</div>