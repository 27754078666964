import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchBarDirective } from '../../search-bar/search-bar.directive';
import { SearchBarService } from '../../../services/search-bar/search-bar.service';
import { SearchBarItem } from '../../../models/search-bar-item.model';
import { SearchComponent } from '../../search-bar/search-component.interface';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {
  @ViewChild(SearchBarDirective, { static: false }) searchBarHost!: SearchBarDirective;
  show: boolean = false;
  searchBarItems: SearchBarItem[] = [];

  constructor(private searchBarService: SearchBarService) {
    this.subscribeSearchBarChanges();
  }

  subscribeSearchBarChanges() {
    this.searchBarService.SearchBarTypeChanges$.subscribe((searchBarItems: SearchBarItem[]) => {
      this.show = searchBarItems.length > 0;
      this.searchBarItems = searchBarItems;
    });
  }

  onSearchBarTypeChange(searchBarItem: SearchBarItem) {
    const viewContainerRef = this.searchBarHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<SearchComponent>(searchBarItem.component);
    componentRef.instance.outputEvent.subscribe(val => searchBarItem.callback(val));
    componentRef.instance.data = searchBarItem.data;
  }
}
