

<app-header [showMenuButton]="false" (menuButtonClicked)="column.toggleSidenav()"></app-header>
<app-one-column #column [showMenu]="false">
<mat-list class="notes-list">
   <div class="inside-list">
        <h2>{{ 'header.notifications.notifications' | translate }}</h2>
        <p>{{unreadCount}} {{ 'header.notifications.unread' | translate }}</p>
    
    <mat-card >
    <mat-list-item *ngFor="let notification of notifications" (click)="onNotificationClicked(notification)" >
          <div matListItemIcon><mat-icon class="circle-icon" [class.read]="notification.read" [class.unread]="!notification.read">mail</mat-icon>
          </div>
        
            <div matListItemTitle>{{notification.title}}</div>
            <div matListItemLine>{{notification.createdAt | date:'h:mm a'}}</div>
        
        <div matListItemMeta>
        <button  mat-button [disabled]="notification.read" color="primary" (click)="markAsRead(notification, $event)" >
          
            {{ 'header.notifications.mark_as_read' | translate }}
        </button>
    </div>
    
    </mat-list-item>
    
</mat-card>
</div>
</mat-list>
</app-one-column>

