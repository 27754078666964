<div class="container">
    <mat-card class="card">
        <mat-card-title>{{ 'unauthorized_page.title' | translate }}</mat-card-title>
        <mat-card-content>
            <p>{{ 'unauthorized_page.message_line_1' | translate }}</p>
            <p>{{ 'unauthorized_page.message_line_2' | translate }}</p>
            <p>{{ 'unauthorized_page.message_line_3' | translate }}</p>
            <button mat-raised-button color="accent" (click)="moveTo('/auth/login')">{{ 'unauthorized_page.login_button' | translate }}</button>
            <span style="margin: 15px"></span>
            <button mat-raised-button color="accent" (click)="moveTo('/')">{{ 'unauthorized_page.home_page_button' | translate }}</button>
        </mat-card-content>
    </mat-card>
</div>