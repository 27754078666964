import { Component, EventEmitter, Output } from '@angular/core';
import { SearchComponent } from '../search-component.interface';
import { SearchBarItem } from 'src/app/core/models/search-bar-item.model';

@Component({
  selector: 'app-text-search',
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.css']
})
export class TextSearchComponent implements SearchComponent {
  @Output() outputEvent: EventEmitter<string> = new EventEmitter<string>();
  data: any;
  term: string = '';

  onTermChange() {
    this.outputEvent.emit(this.term);
  }
}
