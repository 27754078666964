import { Component, EventEmitter, Output } from '@angular/core';
import { SearchComponent } from '../search-component.interface';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-date-range-search',
  templateUrl: './date-range-search.component.html',
  styleUrls: ['./date-range-search.component.css']
})
export class DateRangeSearchComponent implements SearchComponent {
  @Output() outputEvent: EventEmitter<string> = new EventEmitter<string>();
  data: any;

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  onDateRangeSelected() {
    const startDate = this.range.value.start;
    const endDate = this.range.value.end;
    if (startDate && endDate) {
      if (startDate <= endDate) {
        this.outputEvent.emit(startDate + ',' + endDate);
        return;
      }
    }
  }

}
