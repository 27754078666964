<mat-toolbar>
  <img src="assets/images/logo.svg" alt="Logo">
    <!-- <span>{{title | translate}}</span> -->
    <span class="toolbar-spacer"></span>
    <app-search-bar></app-search-bar>
    <span class="toolbar-spacer"></span>
    <button mat-icon-button (click)="onHomeButtonClicked()"><mat-icon  class="material-icons-outlined">home</mat-icon></button>
    <button mat-icon-button [matBadgeHidden]="unreadNotificationsCount == 0" [matBadge]="unreadNotificationsCount" matBadgeSize="small" matBadgeColor="warn" [matMenuTriggerFor]="menu">
        <mat-icon  class="material-icons-outlined">notifications</mat-icon>
    </button>

    <!-- <button mat-icon-button (click)="onDarkModeToggled()">
      <mat-icon  class="material-icons-outlined">{{ isDarkMode ? 'dark_mode' : 'wb_sunny' }}</mat-icon>
    </button> -->
    <mat-divider [vertical]="true" class="vertical-divider"></mat-divider>
    <button mat-icon-button [matMenuTriggerFor]="languageMenu">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
        <span mat-menu-item class=" menu-title" disabled>{{ 'header.change_language' | translate }}</span>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="handleLanguageChange('en')">
          {{ 'header.english_language' | translate }}
        </button>
        <button mat-menu-item (click)="handleLanguageChange('he')">
          {{ 'header.hebrew_language' | translate }}
        </button>
     </mat-menu>
      
    <mat-menu #menu="matMenu"  (closed)="onMenuClosed()">
        <app-notifications (onNotificationChanged)="updateNotificationCounter($event)"></app-notifications>
    </mat-menu>
    <button mat-icon-button (click)="logout()"><mat-icon>logout</mat-icon></button>
   
</mat-toolbar>