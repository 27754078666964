import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadForm } from '../../interfaces/file-upload-form';
import { FileType } from '../../interfaces/enums/file-types';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css'],
})
export class FileUploadDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      allowedTypes?: FileType[];
      maxSize?: number;
    },
    private fb: FormBuilder
  ) {
    this.allowedTypes = this.data.allowedTypes ?? this.allowedTypes;
    this.maxSize = this.data.maxSize ?? this.maxSize;
  }

  private typeToMimeTypeMap = new Map([
    [FileType.IMAGE, 'image/*'],
    [FileType.GIF, 'image/gif'],
    [FileType.VIDEO, 'video/*'],
    [FileType.PDF, 'application/pdf'],
    [FileType.WORD, 'application/msword'],
    [FileType.EXCEL, 'application/vnd.ms-excel'],
    [FileType.OTHER, '*/*'],
  ]);

  fileName = '';
  allowedTypes: FileType[] = [
    FileType.IMAGE,
    FileType.GIF,
    FileType.VIDEO,
    FileType.PDF,
    FileType.WORD,
    FileType.EXCEL,
    FileType.OTHER,
  ];

  // 5MB
  maxSize: number = 5 * 1024 * 1024;

  formGroup = this.fb.group({
    file: [
      null as File | null,
      [
        Validators.required,
        FileUploadDialogComponent.validateFile(this.maxSize),
      ],
    ],
    title: ['', [Validators.required]],
    description: [''],
  });

  @ViewChild('fileInput', { static: false })
  fileInputRef!: ElementRef<HTMLInputElement>;
  @Output() onUpload = new EventEmitter<FileUploadForm>();
  @Input() isLoading = false;

  ngAfterViewInit() {
    this.fileInputRef!.nativeElement.accept = this.allowedTypes
      .map((type) => this.typeToMimeTypeMap.get(type))
      .join(',');
  }

  static validateFile(maxFileSize: number) {
    return (control: AbstractControl<any, any>) => {
      if (control.value) {
        const file = control.value as File;
        if (file.size > maxFileSize) {
          return {
            fileTooLarge: true,
            message: `File size should be less than ${
              maxFileSize / (1024 * 1024)
            }MB`,
          };
        }
      }
      return null;
    };
  }

  closeDialog() {
    this.dialogRef.close();
  }

  uploadFile(event: Event) {
    event.preventDefault();

    if (!this.formGroup.valid) {
      return;
    } else {
      this.onUpload.emit({
        file: this.formGroup.value.file!,
        title: this.formGroup.value.title!,
        description: this.formGroup.value.description!,
      });
    }
  }

  onFileSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      this.formGroup.patchValue({
          file: file,
          title: fileNameWithoutExtension
      });
    }
  }

  @HostListener('drop', ['$event'])
  onFileDropped(event: Event) {
    event.preventDefault();

    const files = (event as any).dataTransfer.files;
    if (files && files.length > 0) {
      let list = new DataTransfer();
      const file = files[0] as File;

      const isAllowed = this.allowedTypes.some((type) =>
        new RegExp(this.typeToMimeTypeMap.get(type) as string).test(file.type)
      );
      if (isAllowed) {
        list.items.add(file);
        this.fileInputRef!.nativeElement.files = list.files;
        this.formGroup.patchValue({ file });
      } else {
        console.log('Invalid file type dropped');
      }
    }
  }

  onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
