import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileDetails } from '../../interfaces/file-details';

@Component({
  selector: 'app-file-details-dialog',
  templateUrl: './file-details-dialog.component.html',
  styleUrls: ['./file-details-dialog.component.css']
})
export class FileDetailsDialogComponent {
  constructor(public dialogRef: MatDialogRef<FileDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  closeDialog() {
    this.dialogRef.close();
  }

  fileDetails: FileDetails | null = null;

  ngOnInit() {
    this.fileDetails = this.data.fileDetail as FileDetails;
  }
}
