import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Direction } from "@angular/cdk/bidi";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private changeDirection = new Subject<Direction>();
  private currentDirection: Direction = "ltr";
  private currentLanguage: Languages = Languages.en;

  constructor(@Inject(DOCUMENT) private document: Document, private translate: TranslateService) { }

  setLanguage(language: Languages) {
    this.currentLanguage = language;
    this.updateCurrentDirectionByCurrentLanguage();

    this.translate.use(language);
    this.changeHtmlElementDirection();
    this.changeDirection.next(this.currentDirection);
  }

  private updateCurrentDirectionByCurrentLanguage() {
    if(this.currentLanguage === Languages.he){
      this.currentDirection = "rtl";
    } else {
      this.currentDirection = "ltr";
    }
  }

  private changeHtmlElementDirection() {
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = this.currentDirection;
  }

  get directionsChanged$() {
    return this.changeDirection.asObservable();
  }

  getCurrentDirection(): Direction {
    return this.currentDirection;
  }

  getCurrentLanguage(): Languages {
    return this.currentLanguage;
  }

}

export enum Languages {
  en = "en",
  he = "he"
}
